import _axios from 'axios';
import { bg } from 'date-fns/locale';
import EventSource from 'eventsource';

import Cookies from 'js-cookie';

import {
  LOCAL_BASE_URL,
  PRODUCTION_BASE_URL,
  STAGING_BASE_URL,
} from '../../constants';
import toast from '../util/toasts';

const axios = _axios.create({});

let HOST = LOCAL_BASE_URL;

switch (process.env.NODE_ENV) {
  case 'development':
    HOST = STAGING_BASE_URL;
    // HOST = LOCAL_BASE_URL;
    break;
  case 'staging':
    HOST = STAGING_BASE_URL;
    break;
  case 'production':
    HOST = PRODUCTION_BASE_URL;
    break;
}

console.log('Environment Variables:');
Object.entries(process.env).forEach(([key, value]) => {
  console.log(`${key}: ${value}`);
  if (key == 'REACT_APP_NOMNOM_TARGET_ENV') {
    HOST = value + '/api/v2';
  }
});
console.log(HOST);

// Checks for forbidden errors and displays a toast
axios.interceptors.response.use(null, (error) => {
  const { status } = error.response;
  if (status === 403) {
    toast.error('You cannot perform this action. Please contact your admin.');
    return;
  }
  return Promise.reject(error);
});

export async function api() {}

// SETUP
api.ready = async function () {
  return axios
    .get(HOST + '/ready', {})
    .then((res) => {
      return true;
    })
    .catch((res) => {
      return false;
    });
};

api.setupLogin = async function (username, password) {
  return axios
    .post(HOST + '/setup/login2/', {
      username: username,
      password: password,
    })
    .then((res) => {
      return res.data;
    });
};

api.registerAccount = async function (
  name,
  uuid,
  id_token,
  access_token,
  sub,
  email,
  username,
) {
  return axios
    .post(
      HOST + '/setup/register_account2',
      {
        node_name: name,
        node_uri: window.location.origin,
        org_uuid: uuid,
        cognito_id_token: id_token,
        cognito_access_token: access_token,
        sub: sub,
        cognito_email: email,
        cognito_username: username,
      },
      {},
    )
    .then((res) => {
      return res.data;
    });
};

api.resetWorkers = async function () {
  return axios.get(HOST + '/setup/restart-service/workers', {}).then((res) => {
    return res.data;
  });
};

api.resetScheduler = async function () {
  return axios
    .get(HOST + '/setup/restart-service/scheduler', {})
    .then((res) => {
      return res.data;
    });
};

api.resetApi = async function () {
  return axios.get(HOST + '/setup/restart-service/api', {}).then((res) => {
    return res.data;
  });
};

// AUTH
api.auth = async function (username, password) {
  return axios
    .post(HOST + '/oauth2/token/', {
      username: username,
      password: password,
    })
    .then((res) => {
      return res.data;
    });
};

// PROJECTS
api.projects = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/projects', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

// USER
api.userPermissions = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/current-user', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

// TASKS
api.tasks = async function (project_uuid) {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/projects/' + project_uuid + '/tasks', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data.results;
    });
};

api.tasks.getOne = async function (projectID, taskID) {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/projects/' + projectID + '/tasks/' + taskID, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};

api.tasks.create = async function (projectID, task) {
  const cookie = Cookies.get('token');

  return axios
    .post(HOST + '/projects/' + projectID + '/tasks', task, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};

api.tasks.delete = async function (projectID, taskID) {
  const cookie = Cookies.get('token');

  return axios
    .delete(`${HOST}/projects/${projectID}/tasks/${taskID}`, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};

api.tasks.put = async function (projectID, taskID, payload) {
  const cookie = Cookies.get('token');

  return axios
    .put(HOST + '/projects/' + projectID + '/tasks/' + taskID, payload, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.tasks.execute = async function (projectID, taskID) {
  const cookie = Cookies.get('token');
  return axios
    .post(
      HOST + '/projects/' + projectID + '/tasks/' + taskID + '/execute',
      null,
      {
        headers: {
          token: cookie,
        },
      },
    )
    .then((res) => res.data);
};

api.tasks.history = async function (projectID, taskID) {
  const cookie = Cookies.get('token');
  return axios
    .get(`${HOST}/projects/${projectID}/tasks/${taskID}/versions`, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.tasks.dependencies = async function (projectID, taskID) {
  const cookie = Cookies.get('token');
  return axios
    .get(`${HOST}/projects/${projectID}/tasks/${taskID}/dependencies`, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data.results);
};

// EXECUTIONS
api.executions = async function (projectID, params) {
  const cookie = Cookies.get('token');
  const defaultParams = { created_start: 0 };
  params = Object.assign({}, defaultParams, params);
  return axios
    .get(HOST + '/projects/' + projectID + '/task_executions2', {
      params,
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data.results;
    });
};

api.executions.getOne = async function (projectID, executionID) {
  const cookie = Cookies.get('token');
  const url = `${HOST}/projects/${projectID}/task_execution/${executionID}`;
  return axios
    .get(url, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};

api.executions.kill = async function (projectID, executionID) {
  const cookie = Cookies.get('token');
  const url = `${HOST}/projects/${projectID}/task_execution/${executionID}/kill`;
  return axios
    .put(url, null, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};

api.executions.statsPlot = async function (projectID, executionID) {
  const cookie = Cookies.get('token');
  const url = `${HOST}/projects/${projectID}/task_execution/${executionID}/stats-plot`;
  return axios
    .get(url, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};

api.executions.logSource = function (projectID, executionID) {
  const cookie = Cookies.get('token');
  return new EventSource(
    `${HOST}/projects/${projectID}/logs/execution/${executionID}/stream`,
    {
      headers: { token: cookie },
    },
  );
};

// SEQUENCES
api.sequences = async function (projectUuid) {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/projects/' + projectUuid + '/sequences', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data.results;
    });
};

api.sequences.getAll = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/sequences', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data.results;
    });
};

api.sequences.getOne = async function (projectUUID, sequenceUUID) {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/projects/' + projectUUID + '/sequences/' + sequenceUUID, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.sequences.delete = async function (projectUuid, sequenceID) {
  const cookie = Cookies.get('token');
  return axios
    .delete(`${HOST}/projects/${projectUuid}/sequences/${sequenceID}`, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.sequences.postCron = async function (
  alias,
  enabled,
  schedule,
  project_uuid,
  sequence_tasks,
) {
  const cookie = Cookies.get('token');

  let json = {
    source_sequence: null,
    trigger_type: 'cron',

    project_uuid,
    enabled,
    alias,
    schedule,
    sequence_tasks,
  };

  return axios
    .post(HOST + '/projects/' + project_uuid + '/sequences', json, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data.results;
    });
};

api.sequences.postCronGraph = async function (
  alias,
  enabled,
  schedule,
  project_uuid,
  sequence_task_graph,
) {
  const cookie = Cookies.get('token');

  let json = {
    source_sequence: null,
    trigger_type: 'cron',

    project_uuid,
    enabled,
    alias,
    schedule,
    sequence_task_graph,
  };

  return axios
    .post(HOST + '/projects/' + project_uuid + '/sequences2', json, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data.results;
    });
};

api.sequences.postSequence = async function (
  alias,
  enabled,
  source_sequence,
  project_uuid,
  sequence_tasks,
) {
  const cookie = Cookies.get('token');

  let json = {
    schedule: '',
    trigger_type: 'sequence',

    project_uuid,
    enabled,
    alias,
    source_sequence,
    sequence_tasks,
  };

  return axios
    .post(HOST + '/projects/' + project_uuid + '/sequences', json, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data.results;
    });
};

api.sequences.postSequenceGraph = async function (
  alias,
  enabled,
  source_sequence,
  project_uuid,
  sequence_task_graph,
) {
  const cookie = Cookies.get('token');

  let json = {
    schedule: '',
    trigger_type: 'sequence',

    project_uuid,
    enabled,
    alias,
    source_sequence,
    sequence_task_graph,
  };

  return axios
    .post(HOST + '/projects/' + project_uuid + '/sequences2', json, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data.results;
    });
};

api.sequences.putCron = async function (
  sequenceUUID,
  alias,
  enabled,
  schedule,
  project_uuid,
  sequence_tasks,
) {
  const cookie = Cookies.get('token');

  let json = {
    source_sequence: null,
    trigger_type: 'cron',

    project_uuid,
    enabled,
    alias,
    schedule,
    sequence_tasks,
  };

  return axios
    .put(
      HOST + '/projects/' + project_uuid + '/sequences/' + sequenceUUID,
      json,
      {
        headers: {
          token: cookie,
        },
      },
    )
    .then((res) => {
      return res.data.results;
    });
};

api.sequences.putCronGraph = async function (
  sequenceUUID,
  alias,
  enabled,
  schedule,
  project_uuid,
  sequence_task_graph,
) {
  const cookie = Cookies.get('token');

  let json = {
    source_sequence: null,
    trigger_type: 'cron',

    project_uuid,
    enabled,
    alias,
    schedule,
    sequence_task_graph,
  };

  return axios
    .put(
      HOST + '/projects/' + project_uuid + '/sequences2/' + sequenceUUID,
      json,
      {
        headers: {
          token: cookie,
        },
      },
    )
    .then((res) => {
      return res.data.results;
    });
};

api.sequences.putSequence = async function (
  sequenceUUID,
  alias,
  enabled,
  source_sequence,
  project_uuid,
  sequence_tasks,
) {
  const cookie = Cookies.get('token');

  let json = {
    schedule: '',
    trigger_type: 'sequence',

    project_uuid,
    enabled,
    alias,
    source_sequence,
    sequence_tasks,
  };

  return axios
    .put(
      HOST + '/projects/' + project_uuid + '/sequences/' + sequenceUUID,
      json,
      {
        headers: {
          token: cookie,
        },
      },
    )
    .then((res) => {
      return res.data.results;
    });
};

api.sequences.putSequenceGraph = async function (
  sequenceUUID,
  alias,
  enabled,
  source_sequence,
  project_uuid,
  sequence_task_graph,
) {
  const cookie = Cookies.get('token');

  let json = {
    schedule: '',
    trigger_type: 'sequence',

    project_uuid,
    enabled,
    alias,
    source_sequence,
    sequence_task_graph,
  };

  return axios
    .put(
      HOST + '/projects/' + project_uuid + '/sequences2/' + sequenceUUID,
      json,
      {
        headers: {
          token: cookie,
        },
      },
    )
    .then((res) => {
      return res.data.results;
    });
};

api.sequences.run = async function (projectID, sequenceID) {
  const cookie = Cookies.get('token');
  const url = `${HOST}/projects/${projectID}/sequences/${sequenceID}/run`;
  return axios
    .post(url, null, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};

api.sequences.patch = async function (projectID, sequenceID, patch) {
  const cookie = Cookies.get('token');
  const url = `${HOST}/projects/${projectID}/sequences/${sequenceID}`;
  return axios
    .patch(url, patch, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};

api.sequences.executions = async function (projectID, sequenceID, filters) {
  const cookie = Cookies.get('token');
  const url = `${HOST}/projects/${projectID}/${sequenceID}/sequence_executions`;
  return axios
    .get(url, {
      params: filters,
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data.results);
};

api.sequences.executions.tasks = async function (
  projectID,
  sequenceExecutionID,
  filters,
) {
  const cookie = Cookies.get('token');
  const url = `${HOST}/projects/${projectID}/task_executions`;
  return axios
    .get(url, {
      params: {
        ...filters,
        sequence_execution_uuid: sequenceExecutionID,
      },
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data.results);
};

api.sequences.kill = async function (projectID, executionID, filters) {
  const cookie = Cookies.get('token');
  const url = `${HOST}/projects/${projectID}/sequence_execution/${executionID}/kill`;
  return axios
    .get(url, {
      params: filters,
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};

// ENGINES
api.engines = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/engines2', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data.results;
    });
};

// CONNECTIONS
api.connections = async function (projectID) {
  const cookie = Cookies.get('token');
  return axios
    .get(`${HOST}/projects/${projectID}/connections`, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.connections.history = async function (projectID, connectionID) {
  const cookie = Cookies.get('token');
  return axios
    .get(`${HOST}/projects/${projectID}/connections/${connectionID}/versions`, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.connectionTypes = async function () {
  const cookie = Cookies.get('token');

  return axios
    .get(HOST + '/connection-types', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data.results;
    });
};

api.connectionDetail = async function (projectID, connectionID) {
  const cookie = Cookies.get('token');
  const url = `${HOST}/projects/${projectID}/connections/${connectionID}`;
  return axios
    .get(url, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

// this UPDATES a connection
api.connectionDetail.put = async function (
  alias,
  connection_type_uuid,
  parameters,
  projectUuid,
  connectionUuid,
) {
  const cookie = Cookies.get('token');
  const json = {
    alias: alias,
    connection_type_uuid: connection_type_uuid,
    project_uuid: projectUuid,
    parameters: parameters,
  };

  const url = `${HOST}/projects/${projectUuid}/connections/${connectionUuid}`;
  return axios.put(url, json, { headers: { token: cookie } }).then((res) => {
    return res.data;
  });
};

// this CREATES a connection
// TODO: FIX THIS
api.connectionDetail.post = async function (
  alias,
  connection_type_uuid,
  parameters,
  projectUuid,
) {
  const cookie = Cookies.get('token');
  const json = {
    alias: alias,
    connection_type_uuid: connection_type_uuid,
    project_uuid: projectUuid,
    parameters: parameters,
  };

  return axios
    .post(HOST + '/projects/' + projectUuid + '/connections', json, {
      headers: { token: cookie },
    })
    .then((res) => {
      return res.data;
    });
};

api.connectionDetail.delete = async function (projectID, connectionID) {
  const cookie = Cookies.get('token');
  const url = `${HOST}/projects/${projectID}/connections/${connectionID}`;
  return axios
    .delete(url, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

// SHARED CONFIGS
api.sharedConfigTypes = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/shared-config-types/', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.sharedConfigs = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/shared-configs/', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.sharedConfigs.post = async function (typeUUID, alias, parameters) {
  const cookie = Cookies.get('token');
  const json = {
    alias: alias,
    shared_config_type_uuid: typeUUID,
    parameters: parameters,
  };

  return axios
    .post(HOST + '/shared-configs/', json, { headers: { token: cookie } })
    .then((res) => {
      return res.data;
    });
};

api.sharedConfigs.delete = async function (uuid) {
  const cookie = Cookies.get('token');
  return axios
    .delete(HOST + '/shared-configs/' + uuid, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.sharedConfigs.put = async function (uuid, typeUUID, parameters) {
  const cookie = Cookies.get('token');
  let x = {};
  const json = {
    alias: parameters.alias,
    parameters: parameters,
    shared_config_type_uuid: typeUUID,
  };

  return axios
    .put(HOST + '/shared-configs/' + uuid + '/', json, {
      headers: { token: cookie },
    })
    .then((res) => {
      return res.data;
    });
};

api.sharedConfigs.history = async function (configID) {
  const cookie = Cookies.get('token');
  return axios
    .get(`${HOST}/shared-configs/${configID}/versions`, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

// SHARED FIELDS
api.sharedFields = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/shared-fields/', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.sharedFields.put = async function (sharedField, uuid) {
  const cookie = Cookies.get('token');
  const json = {
    parameters: sharedField.parameters,
    field_type: sharedField.field_type,
    alias: sharedField.alias,
    options: sharedField.options,
    categories: sharedField.categories,
  };

  return axios
    .put(HOST + '/shared-fields/' + uuid + '/', json, {
      headers: { token: cookie },
    })
    .then((res) => {
      return res.data;
    });
};

api.sharedFields.post = async function (
  field_type,
  alias,
  parameters,
  categories,
  options,
) {
  const cookie = Cookies.get('token');
  const json = {
    parameters: parameters,
    field_type: field_type,
    alias: alias,
    options: options,
    categories: categories,
  };

  return axios
    .post(HOST + '/shared-fields/', json, { headers: { token: cookie } })
    .then((res) => {
      return res.data;
    });
};

api.sharedFields.delete = async function (uuid) {
  const cookie = Cookies.get('token');
  return axios
    .delete(`${HOST}/shared-fields/${uuid}`, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.sharedFields.categories = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/parameter-categories', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

// ADMIN
// SYSTEMLOCK
api.systemlock = {};

// STATUS PAGE
api.status = {};

api.status.versions = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/admin/upgrade/available-versions', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.status.latest = async function () {
  return axios
    .get('https://nomnomdata.builtwithdark.com/current_version', {})
    .then((res) => {
      return res.data;
    });
};

api.systemlock.executions = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/admin/systemlock/executions', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.systemlock.executions.set = async function () {
  const cookie = Cookies.get('token');
  return axios
    .post(
      HOST + '/admin/systemlock/executions/',
      {},
      {
        headers: { token: cookie },
      },
    )
    .then((res) => {
      return res.data;
    });
};

api.systemlock.executions.delete = async function () {
  const cookie = Cookies.get('token');
  return axios
    .delete(HOST + '/admin/systemlock/executions/', {
      headers: { token: cookie },
    })
    .then((res) => {
      return res.data;
    });
};

// TODO: FIX API TO MAKE THIS WORK
api.status.logSource = function () {
  const cookie = Cookies.get('token');
  return new EventSource(`${HOST}/logs/system/stream`, {
    headers: { token: cookie },
  });
};

// APPMANAGEMENT
api.appmanagement = {};
api.appmanagement.engines = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/admin/engines/', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.appmanagement.hide = async function (uuid) {
  const cookie = Cookies.get('token');
  const json = [
    {
      op: 'replace',
      path: '/' + uuid + '/disabled',
      value: true,
    },
  ];
  return axios
    .patch(HOST + '/admin/engines/', json, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return uuid;
    });
};

api.appmanagement.activate = async function (uuid) {
  const cookie = Cookies.get('token');
  const json = [
    {
      op: 'replace',
      path: '/' + uuid + '/disabled',
      value: false,
    },
  ];
  return axios
    .patch(HOST + '/admin/engines/', json, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return uuid;
    });
};

// PERMISSIONS
api.users = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/users/', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.groups = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/groups/', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.groups.post = async function (name) {
  const cookie = Cookies.get('token');
  const json = {
    avatar: null,
    description: '',
    name: name,
    type: 'project',
  };

  return axios
    .post(HOST + '/groups/', json, { headers: { token: cookie } })
    .then((res) => {
      return res.data;
    });
};

api.groups.user = {};
api.groups.user.add = async function (group_uuid, user_uuid) {
  const cookie = Cookies.get('token');
  const json = {};

  return axios
    .put(HOST + '/groups/' + group_uuid + '/users/' + user_uuid, json, {
      headers: { token: cookie },
    })
    .then((res) => {
      return res.data;
    });
};

api.groups.user.remove = async function (group_uuid, user_uuid) {
  const cookie = Cookies.get('token');

  return axios
    .delete(HOST + '/groups/' + group_uuid + '/users/' + user_uuid, {
      headers: { token: cookie },
    })
    .then((res) => {
      return res.data;
    });
};

api.groups.role = {};
api.groups.role.add = async function (group_uuid, role_uuid) {
  const cookie = Cookies.get('token');
  const json = {};

  return axios
    .put(HOST + '/groups/' + group_uuid + '/roles/' + role_uuid, json, {
      headers: { token: cookie },
    })
    .then((res) => {
      return res.data;
    });
};

api.groups.role.remove = async function (group_uuid, role_uuid) {
  const cookie = Cookies.get('token');

  return axios
    .delete(HOST + '/groups/' + group_uuid + '/roles/' + role_uuid, {
      headers: { token: cookie },
    })
    .then((res) => {
      return res.data;
    });
};

api.groups.delete = async function (uuid) {
  const cookie = Cookies.get('token');

  return axios
    .delete(`${HOST}/groups/${uuid}`, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};

api.projects = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/projects/', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.projects.post = async function (alias, description) {
  const cookie = Cookies.get('token');
  const json = {
    alias: alias,
    description: description,
  };

  return axios
    .post(HOST + '/projects/', json, { headers: { token: cookie } })
    .then((res) => {
      return res.data;
    });
};

api.projects.put = async function (uuid, alias, description) {
  const cookie = Cookies.get('token');
  const json = {
    alias: alias,
    description: description,
  };

  return axios
    .put(HOST + '/projects/' + uuid + '/', json, { headers: { token: cookie } })
    .then((res) => {
      return res.data;
    });
};

api.projects.delete = async function (uuid) {
  const cookie = Cookies.get('token');

  return axios
    .delete(`${HOST}/projects/${uuid}/`, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};

api.roles = async function () {
  const cookie = Cookies.get('token');
  return axios
    .get(HOST + '/roles/', {
      headers: {
        token: cookie,
      },
    })
    .then((res) => {
      return res.data;
    });
};

api.roles.post = async function (name, project_uuid, actions, resources) {
  const cookie = Cookies.get('token');
  const json = {
    role_name: name,
    role_type: 'project',
    actions: actions,
    resources: resources,
    description: '',
    project_uuid: project_uuid,
  };

  return axios
    .post(HOST + '/projects/' + project_uuid + '/roles', json, {
      headers: { token: cookie },
    })
    .then((res) => {
      return res.data;
    });
};

api.roles.put = async function (name, uuid, project_uuid, actions, resources) {
  const cookie = Cookies.get('token');
  const json = {
    actions: actions,
    description: '',
    project_uuid: project_uuid,
    resources: resources,
    role_name: name,
    role_type: 'project',
  };

  return axios
    .put(HOST + '/projects/' + project_uuid + '/roles/' + uuid, json, {
      headers: { token: cookie },
    })
    .then((res) => {
      return res.data;
    });
};

api.roles.delete = async function (project_uuid, uuid) {
  const cookie = Cookies.get('token');

  return axios
    .delete(`${HOST}/projects/${project_uuid}/roles/${uuid}`, {
      headers: {
        token: cookie,
      },
    })
    .then((res) => res.data);
};
