import { Image, Divider, Button, Typography, Row, Col, Space, Card } from 'antd';

const { Title, Text } = Typography;

function Engine(props) {
  const item = props.item
  let categories = []
  item.categories.forEach(category => {
    categories.push(<Button>{category.name}</Button>)
  })

  // TODO: this exists because the URL changes all the time and will likely have to be fixed again
  let replaceURL = 'http://appdev.nomnomdata.com/static/favicon/apple-touch-icon.png'
  let targetURL = item.iconURL
  if (targetURL == 'https://stg.nomnomdata.com/static/favicon/apple-touch-icon.png') {
    targetURL = replaceURL
  }

	return (
    <>
      <div className='engine-scroll'>
        <Row align='middle'>
          <Col span={1} />
          <Col span={4}>
            <Image
              width={40}
              src={targetURL}
            />
        </Col>
        <Col span={12}>
          <Title level={5} >{item.alias}</Title>
          <Text type="secondary">{item.description}</Text>
        </Col>
        <Col span={2}/>
        <Col span={2} className='categories-01'>
          <Space>{categories}</Space>
        </Col>
        </Row>
        <Row>
          <Col span={1} />
        </Row>
      </div>
      <Divider />
    </>
	)
}

export default Engine