// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

import DescriptionItem from '../Description/DescriptionItem'
import EnginesContainer from './EnginesContainer'

const useStyles = makeStyles(styles);

import { Row, Col, Space, Card } from 'antd';

export default function EnginesPage() {
  const classes = useStyles();
  return (
    <Space direction='vertical' size={'large'} className='apps-management-pg heading-style-02'>
      <Card style={{ paddingBottom: '.5rem'}} className='m-001'>
        <Row align='middle'>
          <Col span={30}>
            <DescriptionItem classes={classes} title={'Apps'} description='Available Apps on your Nominode.' />
          </Col>
        </Row>
      </Card>
      <Card className='m-600'>
        <EnginesContainer classes={classes} />
      </Card>
    </Space>
  );
}
